import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "management",
    path: "accueil / nos métiers / management / Manager"
  },
  title: "Manager", 
	missions:[
		{
		  text: "Gérer" 
		},
		{
		  text: "Gérer l’/les équipe(s) réalisant la prestation et les hommes qui la composent en la menant avec succès vers les objectifs prévus." 
		},
		{
		  text: "Faire progresser ses collaborateurs et son/ses équipe(s)." 
		},
	],
	key_skills: [
		{
		  text: "Décider" 
		},
		{
		  text: "Communiquer (interne, hiérarchie, client, équipes)" 
		},
		{
		  text: "Se positionner en leader" 
		},
		{
		  text: "Contrôler" 
		},
		{
		  text: "Faire preuve d’écoute" 
		},
		{
		  text: "Analyser et comprendre" 
		},
	],
	prerequisites:[
		{
		  text: "Connaissances techniques et organisationnelles de l’environnement de production" 
		},
		{
		  text: "Expression écrite" 
		},
		{
		  text: "Connaissances des techniques de management de proximité" 
		},
		{
		  text: "Formation supérieure et/ou une expérience de deux ans minimum encadrement d’équipe." 
		},
	],
	activities:[
		{
		  text: "Assurer la relation avec le client" 
		},
		{
		  text: "Porter les engagements de service" 
		},
		{
		  text: "Piloter l’adéquation moyens/résultats et organiser l’équipe dans le but de l’optimiser" 
		},
		{
		  text: "Initier et conduire en permanence les changements" 
		},
		{
		  text: "Motiver et stimuler les collaborateurs" 
		},
		{
		  text: "Fixer et suivre les objectifs de ses collaborateurs et de son équipe et accompagner l’évolution de carrière de ses collaborateurs" 
		},
		{
		  text: "Animer, communiquer, développer le réseau de l’équipe, réguler les relations et les tensions, être force de proposition." 
		}
	]
}
const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;
